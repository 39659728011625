@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap');

body {
  font-family: 'Open Sans', sans-serif;
}

input {
  font-family: 'Open Sans', sans-serif;
}

p {
  font-size: 16px;
  font-weight: 300;
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
}

b {
  font-weight: 600;
}

h1,
h2,
h3,
h4 {
  font-weight: 300;
  margin: 0;
}

h4,
h5,
h6 {
  margin: 0;
}

h2 {
  font-size: 32px;
}

h3 {
  font-size: 20px;
}

h5 {
  font-size: 16px;
}

img {
  width: auto;
  max-width: 100%;
}

.hidden {
  display: none;
}

.section {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

/* ie 11 hacks */
@media all and (-ms-high-contrast: none) {
  .section {
    display: block;
  }
}

.section--grey {
  background-color: #fafafa;
  padding-top: 35px;
  padding-bottom: 35px;
}

.section__wrapper {
  width: 100%;
  max-width: 990px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
}

.section__head {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 1.5rem;
}

.section__sub-title {
  text-align: center;
}

img {
  align-self: center;
}

@media (max-width: 991px) {
  .hide-mobile {
    display: none;
  }
  .section__wrapper {
    max-width: 670px;
  }

  .section--header .section__wrapper {
    max-width: 990px;
  }

  .section__title {
    text-align: center;
  }

  .section__sub-title {
    margin-top: 40px;
  }
}

@media (min-width: 992px) {
  .section {
    margin-bottom: 60px;
  }
  .hide-desktop {
    display: none;
  }

  .blue-underline {
    border-bottom: 1px solid #265da5;
    padding-bottom: 0.5rem;
    width: 100%;
    max-width: 600px;
    text-align: center;
  }
  .section--grey {
    padding-top: 65px;
    padding-bottom: 65px;
  }

  .section__head {
    padding-bottom: 3rem;
  }
  .section__sub-title {
    margin-top: 3em;
  }
}
