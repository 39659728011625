@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

body {
  font-family: 'Open Sans', sans-serif;
}

input {
  font-family: 'Open Sans', sans-serif;
}

p {
  font-size: 16px;
  font-weight: 300;
  -webkit-margin-before: 0.5em;
          margin-block-start: 0.5em;
  -webkit-margin-after: 0.5em;
          margin-block-end: 0.5em;
}

b {
  font-weight: 600;
}

h1,
h2,
h3,
h4 {
  font-weight: 300;
  margin: 0;
}

h4,
h5,
h6 {
  margin: 0;
}

h2 {
  font-size: 32px;
}

h3 {
  font-size: 20px;
}

h5 {
  font-size: 16px;
}

img {
  width: auto;
  max-width: 100%;
}

.hidden {
  display: none;
}

.section {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  margin-bottom: 30px;
}

/* ie 11 hacks */
@media all and (-ms-high-contrast: none) {
  .section {
    display: block;
  }
}

.section--grey {
  background-color: #fafafa;
  padding-top: 35px;
  padding-bottom: 35px;
}

.section__wrapper {
  width: 100%;
  max-width: 990px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.section__head {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  padding-bottom: 1.5rem;
}

.section__sub-title {
  text-align: center;
}

img {
  -ms-flex-item-align: center;
      align-self: center;
}

@media (max-width: 991px) {
  .hide-mobile {
    display: none;
  }
  .section__wrapper {
    max-width: 670px;
  }

  .section--header .section__wrapper {
    max-width: 990px;
  }

  .section__title {
    text-align: center;
  }

  .section__sub-title {
    margin-top: 40px;
  }
}

@media (min-width: 992px) {
  .section {
    margin-bottom: 60px;
  }
  .hide-desktop {
    display: none;
  }

  .blue-underline {
    border-bottom: 1px solid #265da5;
    padding-bottom: 0.5rem;
    width: 100%;
    max-width: 600px;
    text-align: center;
  }
  .section--grey {
    padding-top: 65px;
    padding-bottom: 65px;
  }

  .section__head {
    padding-bottom: 3rem;
  }
  .section__sub-title {
    margin-top: 3em;
  }
}

.section--header {
  margin-bottom: 0;
  position: fixed;
  top: 0;
  background: #fff;
  z-index: 999;
  border-bottom: 2px solid #265da5;
}

.header__items {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  padding: 12px 0;
}

.header__logo {
  height: 45px;
}

.nav__item {
  margin-right: 20px;
  font-size: 20px;
  color: inherit;
  text-decoration: none;
  font-weight: 300;
  cursor: pointer;
}

.nav__items {
  list-style: none;
  display: -ms-flexbox;
  display: flex;
}

.nav__items {
  display: -ms-flexbox;
  display: flex;
}

.lang__list {
  list-style: none;
  display: -ms-flexbox;
  display: flex;
}

.lang__el {
  margin-left: 15px;
  font-size: 20px;
  color: inherit;
  text-decoration: none;
  font-weight: 300;
  cursor: pointer;
}

.lang__el.active {
  border-bottom: 1px solid #505050;
}

@media (max-width: 991px) {
  .section--header.expand {
    border-bottom: 2px solid #265da5;
    -webkit-transition: 0.1s ease;
    -o-transition: 0.1s ease;
    transition: 0.1s ease;
  }

  .section--header.stack {
    -webkit-transition: 0.3s ease;
    -o-transition: 0.3s ease;
    transition: 0.3s ease;
  }

  .nav__items {
    padding: 0;
    position: absolute;
    width: 100%;
    left: 0;
    top: 71px;
    -ms-flex-direction: column;
        flex-direction: column;
    background-color: #fff;
    overflow: hidden;
    margin: 0;
  }

  .lang__list {
    padding-left: 0;
  }

  .lang__el {
    border: none;
  }

  .stack .nav__items {
    height: 0;
    -webkit-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
  }

  .expand .nav__items {
    -webkit-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    height: 100vh;
  }

  .nav__item {
    margin-bottom: 15px;
    margin-left: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #265da5;
    padding-left: 2px;
  }

  .nav__item:first-child {
    margin-top: 20px;
  }
}

@media (min-width: 992px) {
  .nav__hamburger {
    display: none;
  }

  .nav__item:last-child {
    margin-right: 0;
  }
}

.YoutubeBackgroundCustom_container__177U8 {
  position: relative;
  overflow: hidden;
}

.YoutubeBackgroundCustom_videoContainer__tTlS- {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.YoutubeBackgroundCustom_videoInnerContainer__39bDq {
  width: 100%;
  height: 100%;
}

.YoutubeBackgroundCustom_overlay__17PO- {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
}
.YoutubeBackgroundCustom_videoIframe__3YFNE {
  position: relative;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.section--hero {
  background-position: center center;
  background-size: cover;
  margin-top: 70px;
  position: relative;
  overflow: hidden;
}

.hero__title {
  font-weight: 400;
}

.section--hero::after {
  content: '';
  background-color: rgba(12, 44, 83, 0.5);
  width: 100%;
  height: 100%;
  position: absolute;
}

.section__wrapper--hero {
  max-width: 100%;
  position: relative;
  min-height: 60vh;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: end;
      align-items: flex-end;
  z-index: 1;
  opacity: 0;
}

.section__wrapper--hero.playing {
  opacity: 1;
  -webkit-transition: 1s ease-in;
  -o-transition: 1s ease-in;
  transition: 1s ease-in;
}

.hero__content {
  color: #fff;
  padding: 30px;
  text-align: center;
  z-index: 1;
  position: absolute;
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  bottom: 0;
}

.hero__text {
  margin-bottom: 15px;
}

.hero__cta {
  font-size: 20px;
  padding: 15px 40px;
  background-color: #ffaa00;
  border-radius: 35px;
  display: inline-block;
  -webkit-transition: 0.2s ease-out;
  -o-transition: 0.2s ease-out;
  transition: 0.2s ease-out;
}

.hero__cta:hover {
  background-color: #d28c00;
  cursor: pointer;
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
  padding: 15px 50px;
}

.hero__cta:active {
  background-color: #d28c00;
  -webkit-transform: scale(1);
      -ms-transform: scale(1);
          transform: scale(1);
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
}

@media (min-width: 992px) {
  .section--hero {
    margin-top: 83px;
  }
}

.hero__video-container {
  width: 100%;
  height: 100%;
}

.hero__video-container iframe {
  position: relative;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.section--who {
  text-align: center;
}

.section__wrapper--who {
  max-width: 650px;
}

.section__head--who {
  padding-bottom: 0.25rem;
}

.section__text:last-of-type {
  margin-bottom: 0;
}

.section__text--who {
  font-size: 20px;
}

.success__items {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
}

@media screen and (min-width: 992px) {
  .success__item {
    margin-top: 10px;
  }
}

.success__number {
  font-weight: 600;
  color: #265da5;
  margin-bottom: 6px;
  font-size: 20px;
  margin-top: 0;
}

.success__text {
  margin: 0;
}

.success {
  margin-top: 50px;
}

.success__head {
  margin-bottom: 1.5rem;
}

@media (max-width: 374px) {
  .success__text {
    font-size: 14px;
  }
}

@media (min-width: 992px) {
  .success__text {
    font-size: 20px;
  }
}

.audicarousel {
  display: -ms-flexbox;
  display: flex;
}

.audicarousel__slider {
  width: 100%;
  cursor: -webkit-grab;
  cursor: grab;
}

.audicarousel__slider:active {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.audicarousel__item {
  background: #fff;
}

.audicarousel__content {
  background-color: #fff;
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1 0 300px;
      flex: 1 0 300px;
  -ms-flex-direction: column;
      flex-direction: column;
  text-align: left;
  max-width: 640px;
  padding-top: 2rem;
}

.audicarousel__subtitle {
  margin-bottom: 1rem;
}

.carousel .control-dots .dot {
  -webkit-transition: 0.25s ease-in;
  -o-transition: 0.25s ease-in;
  transition: 0.25s ease-in;
  border-radius: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  width: 40px;
  height: 4px;
  opacity: 1;
}

.carousel .control-dots .dot.selected {
  background: #265da5;
}

.control-dots {
  max-width: calc(100% - 320px);
  padding-left: 0 !important;
}

.audicarousel__img {
  height: auto;
}

.section--carousel .carousel .slide {
  background: #fff;
}

.audicarousel .carousel.carousel-slider .control-arrow {
  background: transparent;
  opacity: 1;
}

@media (max-width: 659px) {
  .audicarousel .slide::after {
    content: '';
    position: absolute;
    width: calc(100% - 30px);
    left: 0;
    top: 0;
    height: 100%;
    border-left: 15px solid #fff;
    border-right: 15px solid #fff;
  }

  .audicarousel .slide.selected::after {
    display: none;
  }
}

@media (min-width: 660px) and (max-width: 991px) {
  .audicarousel__item {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (max-width: 991px) {
  .section--carousel .section__wrapper {
    padding-left: 0;
    padding-right: 0;
  }

  .carousel .control-dots {
    display: none;
  }

  .audicarousel__content {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 1.5rem;
    text-align: center;
  }

  .audicarousel .carousel.carousel-slider .control-arrow {
    height: 70%;
  }

  .audicarousel .carousel.carousel-slider .control-arrow.control-next {
    margin-right: 15px;
  }

  .audicarousel .carousel.carousel-slider .control-arrow.control-prev {
    margin-left: 15px;
  }
}

@media (min-width: 992px) {
  .audicarousel__item {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
  }

  .audicarousel__img {
    width: calc(100% - 320px) !important;
    max-width: 640px;
    -ms-flex-positive: 0;
        flex-grow: 0;
    -ms-flex-negative: 1;
        flex-shrink: 1;
    -ms-flex-preferred-size: calc(100% - 320px);
        flex-basis: calc(100% - 320px);
    height: auto;
    border-radius: 15px;
  }

  .audicarousel__content {
    -ms-flex-direction: column;
        flex-direction: column;
    padding-top: 0;
    padding-left: 20px;
  }

  .audicarousel__text {
    padding-right: 15px;
  }

  .audicarousel .carousel.carousel-slider .control-arrow.control-next {
    right: auto;
    left: 612px;
  }
}

.values {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: start;
      align-items: flex-start;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.values__item {
  -ms-flex-positive: 0;
      flex-grow: 0;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -ms-flex-preferred-size: calc(50% - 20px);
      flex-basis: calc(50% - 20px);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  text-align: center;
}

.values_icon {
  margin-bottom: 1.25rem;
}

@media (min-width: 992px) {
  .values__item {
    -ms-flex-positive: 0;
        flex-grow: 0;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    -ms-flex-preferred-size: calc(25% - 20px);
        flex-basis: calc(25% - 20px);
  }
}

/* ie 11 hacks */
@media all and (-ms-high-contrast: none) {
  .values__text {
    width: 100%;
  }
}

.work__img {
  height: auto;
  width: 100%;
  vertical-align: top;
  border: 0;
}

.work__content {
  background-color: #fff;
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1 0 300px;
      flex: 1 0 300px;
  -ms-flex-direction: column;
      flex-direction: column;
  text-align: center;
  max-width: 640px;
  padding-top: 1rem;
}

@media (min-width: 660px) and (max-width: 991px) {
  .work {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (max-width: 991px) {
  .work__content {
    padding-left: 15px;
    padding-right: 15px;
  }

  .section--work .section__wrapper {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 992px) {
  .section__content.work {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
  }

  .work__img {
    width: calc(100% - 320px) !important;
    max-width: 640px;
    -ms-flex-positive: 0;
        flex-grow: 0;
    -ms-flex-negative: 1;
        flex-shrink: 1;
    -ms-flex-preferred-size: calc(100% - 320px);
        flex-basis: calc(100% - 320px);
    height: auto;
    -ms-flex-order: 1;
        order: 1;
    border-radius: 15px;
  }

  .work__content {
    -ms-flex-direction: column;
        flex-direction: column;
    padding-top: 0;
    padding-right: 20px;
  }

  .work__content {
    padding-top: 2rem;
    text-align: left;
  }
}

.work__text:last-of-type {
  margin-bottom: 0;
}

.offices {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.offices__row {
  display: -ms-flexbox;
  display: flex;
}

.offices__item {
  -ms-flex-positive: 0;
      flex-grow: 0;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -ms-flex-preferred-size: calc(100% / 3);
      flex-basis: calc(100% / 3);
  width: calc(100% / 3);
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

.offices__item--text {
  background: #f2f2f2;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  padding: 1.75rem;
  text-align: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.offices__name {
  margin-top: 0;
  margin-bottom: 0;
  -webkit-transition: 0.7s ease-out;
  -o-transition: 0.7s ease-out;
  transition: 0.7s ease-out;
}

.offices__body {
  margin-top: 0;
  margin-bottom: 0;
  max-height: 0;
  overflow: hidden;
  -webkit-transition: 0.7s ease-out;
  -o-transition: 0.7s ease-out;
  transition: 0.7s ease-out;
  opacity: 0;
  cursor: default;
}

.offices__overlay--image {
  position: absolute;
  height: 100%;
  width: 100%;
  -webkit-transition: 0.3s ease-in;
  -o-transition: 0.3s ease-in;
  transition: 0.3s ease-in;
}

.section__content--single {
  text-align: center;
  padding-top: 30px;
}

/* ie 11 hacks */
@media all and (-ms-high-contrast: none) {
  .offices__row {
    width: 100%;
  }

  .offices__img {
    width: 100%;
  }
}

@media (max-width: 659px) {
  .offices__item--text {
    padding: 0.75rem;
  }

  .offices__text {
    width: 100%;
  }

  .offices__name {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -ms-flex-pack: center;
        justify-content: center;
  }

  .offices__item--text {
    background: #fff;
  }
}

@media (max-width: 991px) {
  .offices {
    margin-bottom: -1rem;
  }

  .offices__row {
    -ms-flex-pack: justify;
        justify-content: space-between;
    margin-bottom: 1rem;
  }

  .offices__item {
    -ms-flex-preferred-size: calc((100% / 3) - 0.75rem);
        flex-basis: calc((100% / 3) - 0.75rem);
    width: calc((100% / 3) - 0.75rem);
  }
}

@media (min-width: 992px) {
  .offices__row:hover .offices__body {
    max-height: 300px;
    -webkit-transition: 0.3s ease-in;
    -o-transition: 0.3s ease-in;
    transition: 0.3s ease-in;
    opacity: 1;
  }

  .offices__row:hover .offices__name {
    font-weight: 600;
    -webkit-transition: 0.3s ease-in;
    -o-transition: 0.3s ease-in;
    transition: 0.3s ease-in;
  }
}

.trust {
  margin-top: 35px;
  padding-top: 25px;
  padding-bottom: 25px;
}

@media (max-width: 991px) {
  .trust__wrapper {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 992px) {
  .trust {
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
  }
}

.benefits {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: start;
      align-items: flex-start;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.benefits__item {
  -ms-flex-positive: 0;
      flex-grow: 0;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -ms-flex-preferred-size: calc(50% - 20px);
      flex-basis: calc(50% - 20px);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  text-align: center;
}

.benefits_icon {
  margin-bottom: 1.5rem;
}

@media (min-width: 992px) {
  .benefits__item {
    -ms-flex-positive: 0;
        flex-grow: 0;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    -ms-flex-preferred-size: calc(25% - 20px);
        flex-basis: calc(25% - 20px);
  }
}

/* ie 11 hacks */
@media all and (-ms-high-contrast: none) {
  .benefits__text {
    width: 100%;
  }
}

.video__cont {
  margin-right: auto;
  margin-left: auto;
  position: relative;
}

.video__play {
  width: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -webkit-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
  z-index: 2;
}

.video__play:hover {
  width: 110px;
  cursor: pointer;
}

.video__overlay {
  position: absolute;
  width: 100%;
  z-index: 1;
}

.video__video {
  width: 100%;
}

.video__video > div {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.video__frame-parent iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (max-width: 599px) {
  .section--video .section__wrapper {
    padding-left: 0;
    padding-right: 0;
  }
}

.development {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.items {
  display: -ms-flexbox;
  display: flex;
  margin-top: 40px;
}

.section__sub-title + .items {
  margin-top: calc(1.5rem - 10px);
}

.items__item {
  -ms-flex-preferred-size: calc(100% / 3);
      flex-basis: calc(100% / 3);
  margin-right: 10px;
  position: relative;
  background: #fff;
}

.items__inner {
  position: relative;
}

.items__item:last-child {
  margin-right: 0;
}

.items__title {
  font-size: 20px;
  padding-bottom: 10px;
  font-weight: 100;
  border-bottom-style: solid;
  border-width: 1px;
  border-color: #265da5;
  margin-bottom: 50px;
  text-align: center;
  width: 90%;
  margin-right: auto;
  margin-left: auto;
}

.items__text {
  width: 100%;
  bottom: 0;
  left: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  color: #ffffff;
  padding-right: 12px;
  padding-left: 12px;
  padding-bottom: 8px;
  padding-top: 20px;
  line-height: 22px;
  position: absolute;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(255, 255, 255, 0)), to(#000a1f));
  background-image: -o-linear-gradient(top, rgba(255, 255, 255, 0) 0, #000a1f 100%);
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, #000a1f 100%);
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  padding-top: 30px;
}

.items__text p {
  margin: 0;
}

.items__text:hover {
  cursor: default;
}

.items__inner-title {
  font-weight: 600;
}

.items__inner-subtitle {
  font-size: 12px;
  font-weight: 400;
}

.items__inner-copy {
  font-style: italic;
  margin-bottom: 10px;
  max-height: 0;
  overflow: hidden;
  -webkit-transition: all.7s ease-out;
  -o-transition: all.7s ease-out;
  transition: all.7s ease-out;
  font-weight: 400;
}

.items__inner-copy:empty {
  padding: 0 !important;
}

.items__body {
  border-radius: 15px;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
}

.section--development .control-dots {
  display: none;
}

.section--development .carousel .slide {
  background: #fff;
}

.section__text--development {
  text-align: center;
}

@media (max-width: 991px) {
  .items__carousel {
    width: calc(100% + 30px);
    margin-left: -15px;
  }

  .section--development .carousel.carousel-slider .control-arrow:hover {
    background: transparent;
  }

  .section--development .carousel.carousel-slider .control-arrow {
    top: 0;
    height: calc(100% - 80px);
    opacity: 1;
  }

  .items__item {
    -ms-flex-preferred-size: calc(100% / 3);
        flex-basis: calc(100% / 3);
    margin-right: 10px;
    position: relative;
    background: #fff;
    padding-left: 15px;
    padding-right: 15px;
  }

  .section--development .carousel .control-next.control-arrow {
    right: 15px;
  }

  .section--development .carousel .control-prev.control-arrow {
    left: 15px;
  }
}

@media (max-width: 991px) {
  .section__text--development {
    margin-top: 0;
    font-size: 20px;
  }

  .items__inner-copy--mobile {
    max-height: none;
    max-height: initial;
    padding: 15px 15px 0 15px;
    margin: 0;
  }

  .items__title {
    margin-bottom: 30px;
  }
}

@media (min-width: 992px) {
  .items__body:hover .items__inner-copy {
    max-height: 300px;
    -webkit-transition: all 0.5s ease-out;
    -o-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    opacity: 1;
    cursor: default;
  }

  .items__body:hover .items__info-icon {
    opacity: 0;
    -webkit-transition: 0.2s ease-out;
    -o-transition: 0.2s ease-out;
    transition: 0.2s ease-out;
  }

  .items__info-icon {
    width: 15px;
    height: 15px;
    float: right;
    -webkit-transition: 0.5s ease-in;
    -o-transition: 0.5s ease-in;
    transition: 0.5s ease-in;
  }
}

/* ie 11 hacks */
@media all and (-ms-high-contrast: none) {
  .items__img {
    width: 100%;
  }
}

.jobs__departments {
  -webkit-column-count: 2;
     -moz-column-count: 2;
          column-count: 2;
  grid-column-gap: 2rem;
  -webkit-column-gap: 2rem;
     -moz-column-gap: 2rem;
          column-gap: 2rem;
}

.jobs__dep-name {
  margin-bottom: 12px;
  padding-bottom: 5px;
  border-bottom: 2px solid #265da5;
  color: #3a3a3a;
}

.job {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  color: #000;
  color: initial;
  text-decoration: none;
  border-bottom: 1px solid #ddd;
  padding-bottom: 0.25rem;
  margin-bottom: 1rem;
}

.job--hidden {
  display: none;
}

.job__name {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  text-align: left;
  font-weight: 300;
  color: #3a3a3a;
}

.job__location {
  -ms-flex: 1 0 auto;
      flex: 1 0 auto;
  text-align: right;
  margin-left: 1rem;
  max-width: 150px;
  font-weight: 300;
  color: #3a3a3a;
}

.filters {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
  margin-bottom: 3rem;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.filters__cont {
  -ms-flex: 0 0 100%;
      flex: 0 0 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
}

.filters__select__value-container,
.filters__item--search {
  height: 50px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 10px 20px !important;
}

.filters__item--search {
  -ms-flex: 0 0 100%;
      flex: 0 0 100%;
}

.filters__item--office {
  -ms-flex-positive: 0;
      flex-grow: 0;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -ms-flex-preferred-size: calc(40% - 7px);
      flex-basis: calc(40% - 7px);
}

.filters__item--department {
  -ms-flex-positive: 0;
      flex-grow: 0;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -ms-flex-preferred-size: calc(60% - 7px);
      flex-basis: calc(60% - 7px);
}

.filters__select__indicator-separator {
  display: none;
}

.filters__item::-webkit-input-placeholder {
  color: #ddd !important;
}

.filters__item::-moz-placeholder {
  color: #ddd !important;
}

.filters__item:-ms-input-placeholder {
  color: #ddd !important;
}

.filters__item::-ms-input-placeholder {
  color: #ddd !important;
}

.filters__item::placeholder,
.filters__select__placeholder {
  color: #ddd !important;
}

.filters__item:focus {
  outline: none;
}

.filters__item,
.filters__cont,
.filters__select__control {
  border-radius: 30px !important;
  font-size: 18px;
  font-weight: 300;
}

.filters__select__control {
  border: none !important;
}

.filters__item {
  border: 1px solid #ddd;
}

.jobs__container {
  display: -ms-flexbox;
  display: flex;
  margin-left: -30px;
  width: auto;
}
.jobs__column {
  padding-left: 30px;
  background-clip: padding-box;
}

/* ie 11 hacks */
@media all and (-ms-high-contrast: none) {
  .jobs__column {
    padding-left: 60px;
  }
}

.jobs__department {
  margin-bottom: 30px;
}

@media (min-width: 370px) {
  .job__location {
    margin-left: 3rem;
  }
}

@media (max-width: 659px) {
  .filters__cont--select {
    margin-top: 14px;
  }
}

@media (min-width: 660px) {
  .filters__cont {
    -ms-flex-positive: 0;
        flex-grow: 0;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    -ms-flex-preferred-size: calc(40% - 7px);
        flex-basis: calc(40% - 7px);
  }

  .filters__cont--select {
    -ms-flex-positive: 0;
        flex-grow: 0;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    -ms-flex-preferred-size: calc(60% - 7px);
        flex-basis: calc(60% - 7px);
  }
}

@media (min-width: 992px) {
  .filters__cont {
    -ms-flex-positive: 0;
        flex-grow: 0;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    -ms-flex-preferred-size: calc(50% - 15px);
        flex-basis: calc(50% - 15px);
    width: calc(50% - 15px);
  }

  .filters__cont--select {
    -ms-flex-positive: 0;
        flex-grow: 0;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    -ms-flex-preferred-size: calc(50% - 15px);
        flex-basis: calc(50% - 15px);
    width: calc(50% - 15px);
  }

  .filters__item--office {
    -ms-flex-positive: 0;
        flex-grow: 0;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    -ms-flex-preferred-size: calc(40% - 15px);
        flex-basis: calc(40% - 15px);
  }

  .filters__item--department {
    -ms-flex-positive: 0;
        flex-grow: 0;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    -ms-flex-preferred-size: calc(60% - 15px);
        flex-basis: calc(60% - 15px);
  }
}

/* ie 11 hacks */
@media all and (-ms-high-contrast: none) {
  .filters__cont {
    -ms-flex-preferred-size: 45% !important;
        flex-basis: 45% !important;
  }
}

.filters__clear-btn {
  -webkit-transition: background 0.3s ease;
  -o-transition: background 0.3s ease;
  transition: background 0.3s ease;
  margin: 10px 0 0 auto;
  border: none;
  padding: 0;
  width: auto;
  overflow: visible;
  outline: none;

  background-color: hsl(0, 0%, 95%);
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 30px;

  color: inherit;
  font: inherit;
  font-size: 12px;

  line-height: normal;

  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  -webkit-appearance: none;
}

/* ie 11 hacks */
@media all and (-ms-high-contrast: none) {
  .filters__clear-btn {
    min-width: 100px;
  }
}

.filters__clear-btn:hover,
.filters__clear-btn:focus {
  background-color: hsl(0, 0%, 90%);
}

.section--footer {
  background-color: #3a3a3a;
  margin-bottom: 0;
  padding-top: 60px;
  padding-bottom: 30px;
}

.social {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
}

.social_link {
  margin-left: 1rem;
  margin-right: 1rem;
}

.social__icon {
  height: 22px;
}

.international {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  margin-top: 1.5rem;
  margin-bottom: 2rem;
}

.international__title {
  color: #fff;
  font-size: 20px;
}

.international_icon {
  height: 17px;
  margin-left: 0.4rem;
  margin-right: 0.4rem;
}

@media screen and (min-width: 375px) {
  .international_icon {
    margin-left: 0.6rem;
    margin-right: 0.6rem;
  }
}

@media screen and (min-width: 414px) {
  .international_icon {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }
}

.nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
}

.nav__links {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
}

.nav_link {
  color: #fff;
  text-decoration: none;
  font-size: 20px;
  font-weight: 300;
  margin-bottom: 0.25rem;
}

