.development {
  display: flex;
  flex-wrap: wrap;
}

.items {
  display: flex;
  margin-top: 40px;
}

.section__sub-title + .items {
  margin-top: calc(1.5rem - 10px);
}

.items__item {
  flex-basis: calc(100% / 3);
  margin-right: 10px;
  position: relative;
  background: #fff;
}

.items__inner {
  position: relative;
}

.items__item:last-child {
  margin-right: 0;
}

.items__title {
  font-size: 20px;
  padding-bottom: 10px;
  font-weight: 100;
  border-bottom-style: solid;
  border-width: 1px;
  border-color: #265da5;
  margin-bottom: 50px;
  text-align: center;
  width: 90%;
  margin-right: auto;
  margin-left: auto;
}

.items__text {
  width: 100%;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  color: #ffffff;
  padding-right: 12px;
  padding-left: 12px;
  padding-bottom: 8px;
  padding-top: 20px;
  line-height: 22px;
  position: absolute;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, #000a1f 100%);
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  padding-top: 30px;
}

.items__text p {
  margin: 0;
}

.items__text:hover {
  cursor: default;
}

.items__inner-title {
  font-weight: 600;
}

.items__inner-subtitle {
  font-size: 12px;
  font-weight: 400;
}

.items__inner-copy {
  font-style: italic;
  margin-bottom: 10px;
  max-height: 0;
  overflow: hidden;
  transition: all.7s ease-out;
  font-weight: 400;
}

.items__inner-copy:empty {
  padding: 0 !important;
}

.items__body {
  border-radius: 15px;
  display: flex;
  overflow: hidden;
}

.section--development .control-dots {
  display: none;
}

.section--development .carousel .slide {
  background: #fff;
}

.section__text--development {
  text-align: center;
}

@media (max-width: 991px) {
  .items__carousel {
    width: calc(100% + 30px);
    margin-left: -15px;
  }

  .section--development .carousel.carousel-slider .control-arrow:hover {
    background: transparent;
  }

  .section--development .carousel.carousel-slider .control-arrow {
    top: 0;
    height: calc(100% - 80px);
    opacity: 1;
  }

  .items__item {
    flex-basis: calc(100% / 3);
    margin-right: 10px;
    position: relative;
    background: #fff;
    padding-left: 15px;
    padding-right: 15px;
  }

  .section--development .carousel .control-next.control-arrow {
    right: 15px;
  }

  .section--development .carousel .control-prev.control-arrow {
    left: 15px;
  }
}

@media (max-width: 991px) {
  .section__text--development {
    margin-top: 0;
    font-size: 20px;
  }

  .items__inner-copy--mobile {
    max-height: initial;
    padding: 15px 15px 0 15px;
    margin: 0;
  }

  .items__title {
    margin-bottom: 30px;
  }
}

@media (min-width: 992px) {
  .items__body:hover .items__inner-copy {
    max-height: 300px;
    transition: all 0.5s ease-out;
    opacity: 1;
    cursor: default;
  }

  .items__body:hover .items__info-icon {
    opacity: 0;
    transition: 0.2s ease-out;
  }

  .items__info-icon {
    width: 15px;
    height: 15px;
    float: right;
    transition: 0.5s ease-in;
  }
}

/* ie 11 hacks */
@media all and (-ms-high-contrast: none) {
  .items__img {
    width: 100%;
  }
}
