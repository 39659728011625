.work__img {
  height: auto;
  width: 100%;
  vertical-align: top;
  border: 0;
}

.work__content {
  background-color: #fff;
  display: flex;
  flex: 1 0 300px;
  flex-direction: column;
  text-align: center;
  max-width: 640px;
  padding-top: 1rem;
}

@media (min-width: 660px) and (max-width: 991px) {
  .work {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (max-width: 991px) {
  .work__content {
    padding-left: 15px;
    padding-right: 15px;
  }

  .section--work .section__wrapper {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 992px) {
  .section__content.work {
    display: flex;
    align-items: center;
  }

  .work__img {
    width: calc(100% - 320px) !important;
    max-width: 640px;
    flex: 0 1 calc(100% - 320px);
    height: auto;
    order: 1;
    border-radius: 15px;
  }

  .work__content {
    flex-direction: column;
    padding-top: 0;
    padding-right: 20px;
  }

  .work__content {
    padding-top: 2rem;
    text-align: left;
  }
}

.work__text:last-of-type {
  margin-bottom: 0;
}
