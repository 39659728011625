.section--who {
  text-align: center;
}

.section__wrapper--who {
  max-width: 650px;
}

.section__head--who {
  padding-bottom: 0.25rem;
}

.section__text:last-of-type {
  margin-bottom: 0;
}

.section__text--who {
  font-size: 20px;
}

.success__items {
  display: flex;
  justify-content: space-between;
}

@media screen and (min-width: 992px) {
  .success__item {
    margin-top: 10px;
  }
}

.success__number {
  font-weight: 600;
  color: #265da5;
  margin-bottom: 6px;
  font-size: 20px;
  margin-top: 0;
}

.success__text {
  margin: 0;
}

.success {
  margin-top: 50px;
}

.success__head {
  margin-bottom: 1.5rem;
}

@media (max-width: 374px) {
  .success__text {
    font-size: 14px;
  }
}

@media (min-width: 992px) {
  .success__text {
    font-size: 20px;
  }
}
