.section--header {
  margin-bottom: 0;
  position: fixed;
  top: 0;
  background: #fff;
  z-index: 999;
  border-bottom: 2px solid #265da5;
}

.header__items {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
}

.header__logo {
  height: 45px;
}

.nav__item {
  margin-right: 20px;
  font-size: 20px;
  color: inherit;
  text-decoration: none;
  font-weight: 300;
  cursor: pointer;
}

.nav__items {
  list-style: none;
  display: flex;
}

.nav__items {
  display: flex;
}

.lang__list {
  list-style: none;
  display: flex;
}

.lang__el {
  margin-left: 15px;
  font-size: 20px;
  color: inherit;
  text-decoration: none;
  font-weight: 300;
  cursor: pointer;
}

.lang__el.active {
  border-bottom: 1px solid #505050;
}

@media (max-width: 991px) {
  .section--header.expand {
    border-bottom: 2px solid #265da5;
    transition: 0.1s ease;
  }

  .section--header.stack {
    transition: 0.3s ease;
  }

  .nav__items {
    padding: 0;
    position: absolute;
    width: 100%;
    left: 0;
    top: 71px;
    flex-direction: column;
    background-color: #fff;
    overflow: hidden;
    margin: 0;
  }

  .lang__list {
    padding-left: 0;
  }

  .lang__el {
    border: none;
  }

  .stack .nav__items {
    height: 0;
    transition: 0.3s ease-in-out;
  }

  .expand .nav__items {
    transition: 0.3s ease-in-out;
    height: 100vh;
  }

  .nav__item {
    margin-bottom: 15px;
    margin-left: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #265da5;
    padding-left: 2px;
  }

  .nav__item:first-child {
    margin-top: 20px;
  }
}

@media (min-width: 992px) {
  .nav__hamburger {
    display: none;
  }

  .nav__item:last-child {
    margin-right: 0;
  }
}
