.benefits {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

.benefits__item {
  flex: 0 0 calc(50% - 20px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.benefits_icon {
  margin-bottom: 1.5rem;
}

@media (min-width: 992px) {
  .benefits__item {
    flex: 0 0 calc(25% - 20px);
  }
}

/* ie 11 hacks */
@media all and (-ms-high-contrast: none) {
  .benefits__text {
    width: 100%;
  }
}
