.audicarousel {
  display: flex;
}

.audicarousel__slider {
  width: 100%;
  cursor: grab;
}

.audicarousel__slider:active {
  cursor: grabbing;
}

.audicarousel__item {
  background: #fff;
}

.audicarousel__content {
  background-color: #fff;
  display: flex;
  flex: 1 0 300px;
  flex-direction: column;
  text-align: left;
  max-width: 640px;
  padding-top: 2rem;
}

.audicarousel__subtitle {
  margin-bottom: 1rem;
}

.carousel .control-dots .dot {
  transition: 0.25s ease-in;
  border-radius: 0;
  box-shadow: none;
  width: 40px;
  height: 4px;
  opacity: 1;
}

.carousel .control-dots .dot.selected {
  background: #265da5;
}

.control-dots {
  max-width: calc(100% - 320px);
  padding-left: 0 !important;
}

.audicarousel__img {
  height: auto;
}

.section--carousel .carousel .slide {
  background: #fff;
}

.audicarousel .carousel.carousel-slider .control-arrow {
  background: transparent;
  opacity: 1;
}

@media (max-width: 659px) {
  .audicarousel .slide::after {
    content: '';
    position: absolute;
    width: calc(100% - 30px);
    left: 0;
    top: 0;
    height: 100%;
    border-left: 15px solid #fff;
    border-right: 15px solid #fff;
  }

  .audicarousel .slide.selected::after {
    display: none;
  }
}

@media (min-width: 660px) and (max-width: 991px) {
  .audicarousel__item {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (max-width: 991px) {
  .section--carousel .section__wrapper {
    padding-left: 0;
    padding-right: 0;
  }

  .carousel .control-dots {
    display: none;
  }

  .audicarousel__content {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 1.5rem;
    text-align: center;
  }

  .audicarousel .carousel.carousel-slider .control-arrow {
    height: 70%;
  }

  .audicarousel .carousel.carousel-slider .control-arrow.control-next {
    margin-right: 15px;
  }

  .audicarousel .carousel.carousel-slider .control-arrow.control-prev {
    margin-left: 15px;
  }
}

@media (min-width: 992px) {
  .audicarousel__item {
    display: flex;
    align-items: center;
  }

  .audicarousel__img {
    width: calc(100% - 320px) !important;
    max-width: 640px;
    flex: 0 1 calc(100% - 320px);
    height: auto;
    border-radius: 15px;
  }

  .audicarousel__content {
    flex-direction: column;
    padding-top: 0;
    padding-left: 20px;
  }

  .audicarousel__text {
    padding-right: 15px;
  }

  .audicarousel .carousel.carousel-slider .control-arrow.control-next {
    right: auto;
    left: 612px;
  }
}
