.section--hero {
  background-position: center center;
  background-size: cover;
  margin-top: 70px;
  position: relative;
  overflow: hidden;
}

.hero__title {
  font-weight: 400;
}

.section--hero::after {
  content: '';
  background-color: rgba(12, 44, 83, 0.5);
  width: 100%;
  height: 100%;
  position: absolute;
}

.section__wrapper--hero {
  max-width: 100%;
  position: relative;
  min-height: 60vh;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 1;
  opacity: 0;
}

.section__wrapper--hero.playing {
  opacity: 1;
  transition: 1s ease-in;
}

.hero__content {
  color: #fff;
  padding: 30px;
  text-align: center;
  z-index: 1;
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  bottom: 0;
}

.hero__text {
  margin-bottom: 15px;
}

.hero__cta {
  font-size: 20px;
  padding: 15px 40px;
  background-color: #ffaa00;
  border-radius: 35px;
  display: inline-block;
  transition: 0.2s ease-out;
}

.hero__cta:hover {
  background-color: #d28c00;
  cursor: pointer;
  transition: 0.2s ease-in;
  padding: 15px 50px;
}

.hero__cta:active {
  background-color: #d28c00;
  transform: scale(1);
  transition: 0.2s ease-in;
}

@media (min-width: 992px) {
  .section--hero {
    margin-top: 83px;
  }
}

.hero__video-container {
  width: 100%;
  height: 100%;
}

.hero__video-container iframe {
  position: relative;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}
