.jobs__departments {
  column-count: 2;
  column-gap: 2rem;
}

.jobs__dep-name {
  margin-bottom: 12px;
  padding-bottom: 5px;
  border-bottom: 2px solid #265da5;
  color: #3a3a3a;
}

.job {
  display: flex;
  justify-content: space-between;
  color: initial;
  text-decoration: none;
  border-bottom: 1px solid #ddd;
  padding-bottom: 0.25rem;
  margin-bottom: 1rem;
}

.job--hidden {
  display: none;
}

.job__name {
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  font-weight: 300;
  color: #3a3a3a;
}

.job__location {
  flex: 1 0 auto;
  text-align: right;
  margin-left: 1rem;
  max-width: 150px;
  font-weight: 300;
  color: #3a3a3a;
}

.filters {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3rem;
  flex-wrap: wrap;
}

.filters__cont {
  flex: 0 0 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filters__select__value-container,
.filters__item--search {
  height: 50px;
  box-sizing: border-box;
  padding: 10px 20px !important;
}

.filters__item--search {
  flex: 0 0 100%;
}

.filters__item--office {
  flex: 0 0 calc(40% - 7px);
}

.filters__item--department {
  flex: 0 0 calc(60% - 7px);
}

.filters__select__indicator-separator {
  display: none;
}

.filters__item::placeholder,
.filters__select__placeholder {
  color: #ddd !important;
}

.filters__item:focus {
  outline: none;
}

.filters__item,
.filters__cont,
.filters__select__control {
  border-radius: 30px !important;
  font-size: 18px;
  font-weight: 300;
}

.filters__select__control {
  border: none !important;
}

.filters__item {
  border: 1px solid #ddd;
}

.jobs__container {
  display: flex;
  margin-left: -30px;
  width: auto;
}
.jobs__column {
  padding-left: 30px;
  background-clip: padding-box;
}

/* ie 11 hacks */
@media all and (-ms-high-contrast: none) {
  .jobs__column {
    padding-left: 60px;
  }
}

.jobs__department {
  margin-bottom: 30px;
}

@media (min-width: 370px) {
  .job__location {
    margin-left: 3rem;
  }
}

@media (max-width: 659px) {
  .filters__cont--select {
    margin-top: 14px;
  }
}

@media (min-width: 660px) {
  .filters__cont {
    flex: 0 0 calc(40% - 7px);
  }

  .filters__cont--select {
    flex: 0 0 calc(60% - 7px);
  }
}

@media (min-width: 992px) {
  .filters__cont {
    flex: 0 0 calc(50% - 15px);
    width: calc(50% - 15px);
  }

  .filters__cont--select {
    flex: 0 0 calc(50% - 15px);
    width: calc(50% - 15px);
  }

  .filters__item--office {
    flex: 0 0 calc(40% - 15px);
  }

  .filters__item--department {
    flex: 0 0 calc(60% - 15px);
  }
}

/* ie 11 hacks */
@media all and (-ms-high-contrast: none) {
  .filters__cont {
    flex-basis: 45% !important;
  }
}

.filters__clear-btn {
  transition: background 0.3s ease;
  margin: 10px 0 0 auto;
  border: none;
  padding: 0;
  width: auto;
  overflow: visible;
  outline: none;

  background-color: hsl(0, 0%, 95%);
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 30px;

  color: inherit;
  font: inherit;
  font-size: 12px;

  line-height: normal;

  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  -webkit-appearance: none;
}

/* ie 11 hacks */
@media all and (-ms-high-contrast: none) {
  .filters__clear-btn {
    min-width: 100px;
  }
}

.filters__clear-btn:hover,
.filters__clear-btn:focus {
  background-color: hsl(0, 0%, 90%);
}
